<template>
  <div>
    <div class="burger img-w" @click="toggleMenu">
      <svg viewBox="0 0 49 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="10.1494" y="3" width="28" height="2" fill="white"/>
        <rect x="10.1494" y="15" width="28" height="2" fill="white"/>
        <rect x="10.1494" y="27" width="28" height="2" fill="white"/>
      </svg>
    </div>

    <div class="menu" :class="{'is-visible': menu}">
      <div class="menu__close img-w" @click="toggleMenu">
        <svg viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <rect x="1.41406" y="0.893555" width="28" height="2" transform="rotate(45 1.41406 0.893555)" fill="#104F89"/>
            <rect width="28" height="2" transform="matrix(-0.707107 0.707107 0.707107 0.707107 19.7988 0.893555)" fill="#104F89"/>
          </g>
        </svg>
      </div>
      <div class="menu__logo img-w">
        <img src="@/assets/img/logo.png" alt="">
      </div>
      <ul class="menu__list">
        <li><a href="#" v-scroll-to="'#main'" @click="toggleMenu">Главная</a></li>
        <li><a href="#" v-scroll-to="'#new'" @click="toggleMenu">Новинки</a></li>
        <li><a href="#" v-scroll-to="'#products'" @click="toggleMenu">Продукты</a></li>
        <li><a href="#" v-scroll-to="'#series'" @click="toggleMenu">Pulpy-сериал</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MobileMenu",
    data() {
      return {
        menu: false,
      }
    },

    methods: {
      toggleMenu() {
        this.menu = !this.menu
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "burger";
  @import "menu";
</style>

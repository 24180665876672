<template>
  <header class="header">
    <!--<a href="#" v-scroll-to="'#main'">Главная</a>
    <a href="#" v-scroll-to="'#new'">Новинки</a>
    <a href="#" v-scroll-to="'#products'">Продукты</a>
    <a href="#" v-scroll-to="'#series'">Pulpy-сериал</a>
    <button>Купить онлайн</button>-->

    <a v-for="item in menuList"
       :key="item.attributes.id"
       :class="{'link-button': item.attributes.isButton}"
       :href="item.attributes.link"
       :style="{'order': item.attributes.order}">
      {{ item.attributes.title }}
    </a>
    <a href="#"
       class="link-button"
       @click.prevent="$modal.show('buy-online')" style="order: 99">Купить онлайн

    </a>
  </header>
</template>

<script>
  export default {
    name: "PageHeader",
    computed: {
      menuList() {
        return this.$store.getters.menuList
      }
    },
    mounted() {
      console.log(process.env.VUE_APP_URL);
      this.$store.dispatch('getMenuList');
    }
  }
</script>

<style scoped lang="scss">
  @import "header";
</style>

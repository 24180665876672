<template>
  <div class="popup">
    <div class="popup__overlay"></div>
    <div class="popup__content">
      <p>
        {{ 'Вы покидаете сайт компании АО «Мултон» и переходите на сайт, за который мы не несем ответственность.' | typograf }}
      </p>
      <div class="popup__button-box">
        <a :href="url">
          <button class="popup__button">ок</button>
        </a>
        <button @click="hide" class="popup__button popup__button--secondary">отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "OutPopup",
    data() {
      return {
        popup: false,
      }
    },
    computed: {
      url() {
        return this.$store.getters.popupUrl
      }
    },
    methods: {
      hide() {
        const data = {
          val: false
        }
        this.$store.commit('CHANGE_POPUP_VISIBILITY', data)
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "popup";
</style>

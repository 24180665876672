<template>
  <section class="kv">
    <div class="kv__img img-w">
      <img class="portrait-visible" src="@/assets/img/kv-720.png" alt="">
      <img @click.prevent="$modal.show('buy-online')"
           class="mobile-visible"
           src="@/assets/img/kv-mob.png"
           alt="">
    </div>

    <div class="kv__fruits mobile-hidden">
      <div class="kv__packshot">
        <img src="@/assets/img/kv/kv-img3.png" alt="">
      </div>

      <img class="kv__fruit kv__fruit--logo portrait-hidden" src="@/assets/img/kv/logo.png" alt="">

      <div class="kv__pieces-box">
        <img class="kv__pieces-slug" src="@/assets/img/kv/kv-img3.png" alt="">
        <img class="kv__fruit kv__fruit--1" src="@/assets/img/kv/1.png" alt="">
        <img class="kv__fruit kv__fruit--2" src="@/assets/img/kv/2.png" alt="">
        <img class="kv__fruit kv__fruit--3" src="@/assets/img/kv/3.png" alt="">
        <img class="kv__fruit kv__fruit--4 portrait-hidden" src="@/assets/img/kv/4.png" alt="">
        <img class="kv__fruit kv__fruit--5 portrait-hidden" src="@/assets/img/kv/5.png" alt="">
        <img class="kv__fruit kv__fruit--6 portrait-hidden" src="@/assets/img/kv/6.png" alt="">
      </div>

    </div>
  </section>
</template>

<script>
  export default {
    name: "KeyVisual"
  }
</script>

<style scoped lang="scss">
  @import "kv";
</style>

<template>
  <div id="app">
    <div class="circle1"></div>
    <div class="circle3"></div>

    <MobileMenu class="mobile-visible" />
    <PageHeader id="main" />

    <router-view />

    <PageHeader />

    <PageFooter />

    <OutPopup v-if="$store.getters.isPopupVisible" />

    <modal name="buy-online" :scrollable="true" class="buy-online" height="auto">
      <div class="modal__close img-w" @click="$modal.hide('buy-online')">
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26.5748 22.0092L22.1767 26.4073L39.7693 44L22.1767 61.5927L26.5748 65.9908L44.1675 48.3982L61.7602 65.9908L66.1583 61.5927L48.5657 44L66.1583 26.4073L61.7602 22.0092L44.1675 39.6018L26.5748 22.0092Z"
            fill="white"
          />
        </svg>
      </div>

      <div class="modal__big-img img-w">
        <img class="mobile-hidden" src="@/assets/img/modal-pack.png" alt="" />
        <img class="mobile-visible" src="@/assets/img/modal-pack-mob.png" alt="" />
      </div>

      <ModalFooter v-if="commonPopup.attributes" :metro="commonPopup.attributes.metroLink" :selgros="commonPopup.attributes.selgrosLink" class="no-starred" />
    </modal>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #fff;
  min-height: 100vh;
  font-family: 'PF Din Text Comp Pro';
  position: relative;
  overflow-x: hidden;
  background: #8bb8e8;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(180deg, #0062c9 0%, #8cb9e8 100%);
  }
  &:after {
    top: auto;
    bottom: 0;
    height: 300vh;
    background: linear-gradient(180deg, #8cb9e8 0%, #2277d0 100%);
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #fff;

    &.router-link-exact-active {
      color: #fff;
    }
  }
}
</style>
<script>
import PageFooter from './components/PageFooter/PageFooter';
import PageHeader from './components/PageHeader/PageHeader';
import MobileMenu from './components/MobileMenu/MobileMenu';
import OutPopup from './components/OutPopup/OutPopup';
import ModalFooter from './components/ModalFooter/ModalFooter';
export default {
  components: { ModalFooter, OutPopup, MobileMenu, PageHeader, PageFooter },
  computed: {
    commonPopup() {
      return this.$store.getters.commonPopup;
    },
  },
  mounted() {
    this.$store.dispatch('getCommonPopup');
  },
};
</script>

<template>
  <section class="series" v-if="isReady" id="series">
    <img src="@/assets/img/line.png" class="series__line series__line--top" alt="" loading="lazy" />
    <div class="series-head">
      <div class="series-head-block">
        <img src="@/assets/img/series/head.png" class="series-head__img" alt="">
        <div class="series-head-block__text">
          <h2>смотри сериал</h2>
          <p>Скука взяла вас в&nbsp;плен? Рутина мешает наслаждаться жизнью? Окунитесь в&nbsp;сочную мультивселенную. Встряхните свою реальность вместе с&nbsp;Добрый Pulpy и&nbsp;героями сериала!</p>
        </div>
      </div>
    </div>

    <div class="series__block">
      <swiper
        class="series-swiper"
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        :loop="true"
        :thumbs="{ swiper: thumbsSwiper }" 
      >
        <template #wrapper-start>
          <swiper-slide
            v-for="(item, i) in serieses"
            :key="i"
          >
            <div v-html="item.attributes.video"></div>
          </swiper-slide>
        </template>
      </swiper>
      <swiper
        class="series-swiper-thumbs"
        :modules="modules"
        @swiper="setThumbsSwiper"
        :navigation="{
          prevEl: prev,
          nextEl: next,
        }"
        slidesPerView="3"
        :spaceBetween="20"
        :breakpoints="{ 600:{ slidesPerView:3 }, 900:{ slidesPerView:6, } }"
        :centeredSlides="false"
        :loop="false"
        :slideToClickedSlide="true"
        ref="swiper"
      >
        <template #wrapper-start>
          <swiper-slide
            v-for="(item, i) in serieses"
            :key="i"
          >
            <img v-if="item.attributes.preview.data" class="series-swiper-thumbs__img" :src="$store.getters.staticUrl + item.attributes.preview.data.attributes.url" alt="">
            <span class="series-swiper-thumbs__title">{{ item.attributes.name }}</span>
          </swiper-slide>
        </template>
      </swiper>
      <div class="series-navigation">
        <div ref="prev" class="series-navigation-prev">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="black" fill-opacity="0.2"/>
            <path d="M22.9167 13.333L16.25 19.9997L22.9167 26.6663" stroke="white" stroke-width="3"/>
          </svg>
        </div>
        <div ref="next" class="series-navigation-next">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" transform="matrix(-1 0 0 1 40 0)" fill="black" fill-opacity="0.2"/>
            <path d="M17.0833 13.333L23.75 19.9997L17.0833 26.6663" stroke="white" stroke-width="3"/>
          </svg>
        </div>
      </div>
    </div>
    <img src="@/assets/img/line.png" class="series__line" alt="" loading="lazy" />
  </section>
</template>

<script>
import { ref } from 'vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation, Thumbs } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

export default {
  components: { Swiper, SwiperSlide },
  name: 'ProductsBlock',
  setup() {
    const prev = ref(null);
    const next = ref(null);
    return { modules: [Autoplay, Navigation, Thumbs],prev,
      next, };
  },
  data() {
    return {
      isReady: false,
      thumbsSwiper: null
    };
  },
  computed: {
    serieses() {
      return this.$store.getters.serieses;
    },
  },
  methods: {
    setThumbsSwiper(swiper) {
      this.thumbsSwiper = swiper;
    },
  },
  async mounted() {
    await this.$store.dispatch('getSerieses');
    this.isReady = true;
  },
};
</script>

<style lang="scss">
@import 'series';

p {
  white-space: pre-wrap;
}
</style>

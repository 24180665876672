<template>
  <footer class="footer" v-if="isReady">
    <div class="footer__buttons">
      <a :href="footer.attributes.ytUrl" target="_blank" class="footer__yt-icon">
        <svg viewBox="0 0 81 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M71.8309 1.85491C75.2576 2.80741 77.9594 5.59693 78.8819 9.13484C80.5952 15.5984 80.5294 29.0697 80.5294 29.0697C80.5294 29.0697 80.5295 42.473 78.8819 48.9365C77.9594 52.4745 75.2576 55.264 71.8309 56.2164C65.5707 57.9173 40.5296 57.9173 40.5296 57.9173C40.5296 57.9173 15.5544 57.9173 9.22827 56.1485C5.80158 55.1959 3.09979 52.4064 2.17723 48.8684C0.529785 42.473 0.529785 29.0016 0.529785 29.0016C0.529785 29.0016 0.529785 15.5984 2.17723 9.13484C3.09979 5.59693 5.86749 2.73938 9.22827 1.78687C15.4885 0.0859375 40.5296 0.0859375 40.5296 0.0859375C40.5296 0.0859375 65.5707 0.0859375 71.8309 1.85491ZM53.3798 29.0018L32.5561 41.3844V16.619L53.3798 29.0018Z" fill="white"/>
        </svg>
      </a>
      <a :href="footer.attributes.vkUrl" target="_blank" class="footer__vk-icon">
        <svg viewBox="0 0 83 49" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M80.5187 4.06509C81.0885 2.22191 80.5187 0.867188 77.8079 0.867188H68.8452C66.566 0.867188 65.5159 2.03734 64.9462 3.32806C64.9462 3.32806 60.388 14.1118 53.9308 21.1166C51.8418 23.1442 50.8923 23.7887 49.7528 23.7887C49.1829 23.7887 48.3584 23.1442 48.3584 21.3011V4.06509C48.3584 1.85278 47.6971 0.867188 45.7976 0.867188H31.713C30.289 0.867188 29.4326 1.8938 29.4326 2.86696C29.4326 4.9643 32.6604 5.44776 32.9933 11.3464V24.1585C32.9933 26.9673 32.4709 27.4769 31.3313 27.4769C28.2929 27.4769 20.9024 16.6441 16.5182 4.24965C15.659 1.84034 14.7973 0.867188 12.5066 0.867188H3.54371C0.982871 0.867188 0.470703 2.03734 0.470703 3.32806C0.470703 5.63232 3.50978 17.0617 14.6193 32.1775C22.0266 42.4995 32.4607 48.0961 41.9563 48.0961C47.6542 48.0961 48.3584 46.8531 48.3584 44.7124V36.9102C48.3584 34.4245 48.8981 33.9286 50.7016 33.9286C52.0318 33.9286 54.3103 34.5736 59.6287 39.5507C65.7054 45.4488 66.7068 48.0961 70.1255 48.0961H79.0884C81.6493 48.0961 82.9298 46.8531 82.1909 44.4004C81.3829 41.9556 78.4816 38.4098 74.6313 34.2051C72.5423 31.8082 69.4084 29.2275 68.4596 27.9374C67.1299 26.2782 67.5096 25.5411 68.4596 24.0672C68.4596 24.0672 79.379 9.13535 80.5187 4.0657V4.06509Z" fill="white"/>
        </svg>
      </a>
    </div>

    <div class="footer__phone">{{ footer.attributes.phone }}</div>

    <div class="footer__copy">{{ footer.attributes.copyright }}</div>
  </footer>
</template>

<script>
  export default {
    name: "PageFooter",
    data() {
      return {
        isReady: false,
      }
    },
    computed: {
      footer() {
        return this.$store.getters.footer
      }
    },

    async mounted() {
      await this.$store.dispatch('getFooter')
      this.isReady = true
    }
  }
</script>

<style scoped lang="scss">
  @import "footer";
</style>

import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: `${process.env.VUE_APP_URL}/api`,
    staticUrl: `${process.env.VUE_APP_URL}`,
    isPopupVisible: false,
    popupUrl: '',
    menuList: [],
    novelties: {},
    video: {},
    footer: {},
    commonPopup: {},
    serieses: []
  },
  getters: {
    staticUrl: state => {
      return state.staticUrl;
    },
    isPopupVisible: state => {
      return state.isPopupVisible;
    },
    popupUrl: state => {
      return state.popupUrl;
    },
    menuList: state => {
      return state.menuList;
    },
    commonPopup: state => {
      return state.commonPopup;
    },
    novelties: state => {
      return state.novelties;
    },
    video: state => {
      return state.video;
    },
    serieses: state => {
      return state.serieses;
    },
    products: state => {
      return state.products;
    },
    footer: state => {
      return state.footer;
    },
  },
  mutations: {
    CHANGE_POPUP_VISIBILITY: (state, data) => {
      state.isPopupVisible = data.val;
      if (data.url) state.popupUrl = data.url;
    },
    SET_MENU_LIST: (state, data) => {
      state.menuList = data;
    },
    SET_COMMON_POPUP: (state, data) => {
      state.commonPopup = data;
    },
    SET_NOVELTIES: (state, data) => {
      state.novelties = data;
    },
    SET_VIDEO: (state, data) => {
      state.video = data;
    },
    SET_SERIESES: (state, data) => {
      state.serieses = data;
    },
    SET_FOOTER: (state, data) => {
      state.footer = data;
    },
    SET_PRODUCTS: (state, data) => {
      state.products = data;
    },
  },
  actions: {
    getMenuList({ commit, state }) {
      return axios.get(`${state.apiUrl}/menus?populate=*`).then(r => {
        const obj = r.data.data;
        if (obj) {
          commit('SET_MENU_LIST', obj);
        }
      });
    },
    getCommonPopup({ commit, state }) {
      return axios.get(`${state.apiUrl}/popap-kupit-onlajn?populate=*`).then(r => {
        const obj = r.data.data;
        if (obj) {
          commit('SET_COMMON_POPUP', obj);
        }
      });
    },
    getNovelties({ commit, state }) {
      return axios.get(`${state.apiUrl}/novelty?populate=*`).then(r => {
        const obj = r.data.data;
        if (obj) {
          commit('SET_NOVELTIES', obj);
        }
      });
    },
    getVideo({ commit, state }) {
      return axios.get(`${state.apiUrl}/video?populate=*`).then(r => {
        const obj = r.data.data;
        if (obj) {
          commit('SET_VIDEO', obj);
        }
      });
    },
    getSerieses({ commit, state }) {
      return axios.get(`${state.apiUrl}/serieses?populate=*`).then(r => {
        const obj = r.data.data;
        if (obj) {
          commit('SET_SERIESES', obj);
        }
      });
    },
    getProducts({ commit, state }) {
      return axios.get(`${state.apiUrl}/product?populate=product.bg,product.piece1,product.piece2,product.fullImage,product.ingrs.icon,product.ingrs2.icon`).then(r => {
        const obj = r.data.data;
        if (obj) {
          commit('SET_PRODUCTS', obj);
        }
      });
    },
    getFooter({ commit, state }) {
      return axios.get(`${state.apiUrl}/footer?populate=*`).then(r => {
        const obj = r.data.data;
        if (obj) {
          commit('SET_FOOTER', obj);
        }
      });
    },
  },
  modules: {},
});

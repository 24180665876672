<template>
  <section class="main" v-if="isReady">
    <div class="circle2"></div>

    <h2>{{ products.attributes.title }}</h2>

    <div class="main__items">
      <div class="main__item" @click="$modal.show('product-1')">
        <!--<div class="main__item-img img-w">
          <img src="@/assets/img/main/1.png" alt="">
        </div>-->

        <div class="main__anim-box" v-in-viewport>
          <div class="anim__bg img-w">
            <img src="@/assets/img/prod1/bg.png" alt="" />
          </div>
          <div class="anim__piece1 img-w">
            <img src="@/assets/img/prod1/piece1.png" alt="" />
          </div>
          <div class="anim__piece2 img-w">
            <img src="@/assets/img/prod1/piece2.png" alt="" />
          </div>
          <div class="anim__bottle img-w">
            <img src="@/assets/img/prod1/bottle.png" alt="" />
          </div>
        </div>
        <h3 class="main__product-title">Pulpy Апельсин</h3>
      </div>

      <div class="main__item" @click="$modal.show('product-2')">
        <div class="main__anim-box" v-in-viewport>
          <div class="anim__bg anim__bg--2 img-w">
            <img src="@/assets/img/prod2/bg.png" alt="" />
          </div>
          <div class="anim__piece1 anim__piece1--2 img-w">
            <img src="@/assets/img/prod2/piece1.png" alt="" />
          </div>
          <div class="anim__piece2 anim__piece2--2 img-w">
            <img src="@/assets/img/prod2/piece2.png" alt="" />
          </div>
          <div class="anim__bottle img-w">
            <img src="@/assets/img/prod2/bottle.png" alt="" />
          </div>
        </div>
        <h3 class="main__product-title">Pulpy Тропический</h3>
      </div>

      <div class="main__item new-item" @click="$modal.show('product-6')">
        <div class="main__anim-box" v-in-viewport>
          <div class="anim__bg anim__bg--6 img-w">
            <img src="@/assets/img/prod6/bg.png" alt="" />
          </div>
          <div class="anim__piece1 anim__piece1--6 img-w">
            <img src="@/assets/img/prod6/piece1.png" alt="" />
          </div>
          <div class="anim__bottle anim__bottle--6 img-w">
            <img src="@/assets/img/prod6/bottle.png" alt="" />
          </div>
        </div>
        <h3 class="main__product-title">Pulpy вишня</h3>
      </div>

      <!-- <div class="main__item" @click="$modal.show('product-5')">
        <div class="main__anim-box" v-in-viewport>
          <div class="anim__bg anim__bg--5 img-w">
            <img src="@/assets/img/prod5/bg.png" alt="" />
          </div>
          <div class="anim__piece1 anim__piece1--5 img-w">
            <img src="@/assets/img/prod5/piece1.png" alt="" />
          </div>
          <div class="anim__piece2 anim__piece2--5 img-w">
            <img src="@/assets/img/prod5/piece2.png" alt="" />
          </div>
          <div class="anim__bottle img-w">
            <img src="@/assets/img/prod5/bottle.png" alt="" />
          </div>
        </div>
        <h3 class="main__product-title">
          Pulpy Клубника-арбуз <br />
          с кусочками алоэ
        </h3>
      </div> -->

      <div class="main__item" @click="$modal.show('product-3')">
        <div class="main__anim-box" v-in-viewport>
          <div class="anim__bg anim__bg--3 img-w">
            <img src="@/assets/img/prod3/bg.png" alt="" />
          </div>
          <div class="anim__piece1 anim__piece1--3 img-w">
            <img src="@/assets/img/prod3/piece1.png" alt="" />
          </div>
          <div class="anim__piece2 anim__piece2--3 img-w">
            <img src="@/assets/img/prod3/piece2.png" alt="" />
          </div>
          <div class="anim__bottle img-w">
            <img src="@/assets/img/prod3/bottle.png" alt="" />
          </div>
        </div>
        <h3 class="main__product-title">
          Pulpy Гуава-маракуйя <br />
          с кусочками кокосового желе
        </h3>
      </div>

      <div class="main__item" @click="$modal.show('product-4')">
        <div class="main__anim-box" v-in-viewport>
          <div class="anim__bg anim__bg--4 img-w">
            <img src="@/assets/img/prod4/bg.png" alt="" />
          </div>
          <div class="anim__piece1 anim__piece1--4 img-w">
            <img src="@/assets/img/prod4/piece1.png" alt="" />
          </div>
          <div class="anim__piece2 anim__piece2--4 img-w">
            <img src="@/assets/img/prod4/piece2.png" alt="" />
          </div>
          <div class="anim__bottle img-w">
            <img src="@/assets/img/prod4/bottle.png" alt="" />
          </div>
        </div>
        <h3 class="main__product-title">
          Pulpy ананас-манго <br />
          с кусочками ананаса
        </h3>
      </div>
      <div class="main__item" @click="$modal.show('product-7')">
        <div class="main__anim-box" v-in-viewport>
          <div class="anim__bg anim__bg--7 img-w">
            <img src="@/assets/img/prod7/bg.png" alt="" />
          </div>
          <div class="anim__piece1 anim__piece1--7 img-w">
            <img src="@/assets/img/prod7/piece1.png" alt="" />
          </div>
          <div class="anim__piece2 anim__piece2--7 img-w">
            <img src="@/assets/img/prod7/piece2.png" alt="" />
          </div>
          <div class="anim__bottle img-w">
            <img src="@/assets/img/prod7/bottle.png" alt="" />
          </div>
        </div>
        <h3 class="main__product-title">
          Pulpy GALAXY Edition
          <br>с кусочками клубники и&nbsp;банана
        </h3>
      </div>
    </div>

    <modal v-for="product in list" :key="product.id" :name="`product-${product.order}`" :scrollable="true" height="auto">
      <div class="modal__close img-w" @click="$modal.hide(`product-${product.order}`)">
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M26.5748 22.0092L22.1767 26.4073L39.7693 44L22.1767 61.5927L26.5748 65.9908L44.1675 48.3982L61.7602 65.9908L66.1583 61.5927L48.5657 44L66.1583 26.4073L61.7602 22.0092L44.1675 39.6018L26.5748 22.0092Z"
            fill="white"
          />
        </svg>
      </div>

      <div class="modal__df">
        <div class="modal__img img-w">
          <img v-if="product.fullImage.data" :src="staticUrl + product.fullImage.data.attributes.url" alt="" />
          <img v-if="!product.fullImage.data && product.id == 6" src="@/assets/img/prod2.png" alt="" />
          <img v-if="!product.fullImage.data && product.id == 4" src="@/assets/img/prod4/prod4.png" alt="" />
          <div class="modal__note">* Полный состав см на этикетке продукции</div>
        </div>
        <div class="modal__column">
          <h3 class="modal__title">
            {{ product.title | typograf }}
          </h3>
          <div class="modal__volume"><span class="modal__part-title">Объем: </span> {{ product.volume }}</div>
          <p class="modal__descr">
            {{ product.description | typograf }}
          </p>
          <div class="modal__ingr">
            <span class="modal__part-title">Ингредиенты*</span>
          </div>
          <div class="modal__content-columns">
            <ul class="modal__ingr-list">
              <li v-for="item in product.ingrs.data" :key="item.id">
                <span class="icon img-w">
                  <img v-if="item.attributes.icon.data" :src="staticUrl + item.attributes.icon.data.attributes.url" alt="" />
                  <img v-if="!item.attributes.icon.data" src="@/assets/img/icons/cherry.png" alt="" />
                </span>
                {{ item.attributes.title }}
              </li>
            </ul>
            <ul class="modal__ingr-list modal__ingr-list--2nd">
              <li v-for="item in product.ingrs2.data" :key="item.id">
                <span class="icon img-w">
                  <img v-if="item.attributes.icon.data" :src="staticUrl + item.attributes.icon.data.attributes.url" alt="" />
                  <img v-if="!item.attributes.icon.data" src="@/assets/img/icons/ashberry.png" alt="" />
                </span>
                {{ item.attributes.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ModalFooter :id="product.id" :metro="product.metroLink" :selgros="product.selgrosLink" />
    </modal>

    <!--<modal name="product-1" :scrollable="true" height="auto">
      <div class="modal__close img-w" @click="$modal.hide('product-1')">
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.5748 22.0092L22.1767 26.4073L39.7693 44L22.1767 61.5927L26.5748 65.9908L44.1675 48.3982L61.7602 65.9908L66.1583 61.5927L48.5657 44L66.1583 26.4073L61.7602 22.0092L44.1675 39.6018L26.5748 22.0092Z" fill="white"/>
        </svg>
      </div>

      <div class="modal__df">
        <div class="modal__img img-w">
          <img src="@/assets/img/main/1.png" alt="">
        </div>
        <div class="modal__column">
          <h3 class="modal__title">
            Pulpy Апельсин
          </h3>
          <div class="modal__volume">
            <span class="modal__part-title">Объем: </span> 0,45л, 0,9л
          </div>
          <p class="modal__descr">
            {{ 'Встряхни Pulpy Апельсин и открой для себя вкусный микс натурального апельсинового сока, артезианской воды и мякоти цитрусовых' | typograf }}
          </p>
          <div class="modal__ingr">
            <span class="modal__part-title">Ингредиенты*</span>
          </div>
          <div class="modal__content-columns">
            <ul class="modal__ingr-list">
              <li>
                <span class="icon icon--orange"></span>
                Апельсиновый сок
              </li>
              <li>
                <span class="icon icon--orange"></span>
                Мякость апельсина
              </li>
            </ul>
            <ul class="modal__ingr-list modal__ingr-list--2nd">
              <li>
                <span class="icon icon--water"></span>
                Артезианская вода
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalFooter />

    </modal>

    <modal name="product-2" :scrollable="true" height="auto">
      <div class="modal__close img-w" @click="$modal.hide('product-2')">
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.5748 22.0092L22.1767 26.4073L39.7693 44L22.1767 61.5927L26.5748 65.9908L44.1675 48.3982L61.7602 65.9908L66.1583 61.5927L48.5657 44L66.1583 26.4073L61.7602 22.0092L44.1675 39.6018L26.5748 22.0092Z" fill="white"/>
        </svg>
      </div>

      <div class="modal__df">
        <div class="modal__img img-w">
          <img src="@/assets/img/main/2.png" alt="">
        </div>
        <div class="modal__column">
          <h3 class="modal__title">
            Pulpy Тропический
          </h3>
          <div class="modal__volume">
            <span class="modal__part-title">Объем: </span> 0,45л, 0,9л
          </div>
          <p class="modal__descr">
            {{ 'Встряхни Pulpy Тропический и открой для себя вкусный микс сока тропических фруктов, артезианской воды и натуральной мякоти цитрусовых' | typograf }}
          </p>
          <div class="modal__ingr">
            <span class="modal__part-title">Ингредиенты*</span>
          </div>
          <div class="modal__content-columns">
            <ul class="modal__ingr-list">
              <li>
                <span class="icon icon--apple"></span>
                Яблочный сок
              </li>
              <li>
                <span class="icon icon--grape"></span>
                Виноградный сок
              </li>
              <li>
                <span class="icon icon--orange"></span>
                Апельсиновый сок
              </li>
              <li>
                <span class="icon icon--tangerine"></span>
                Мандариновый сок
              </li>
              <li>
                <span class="icon icon--banana"></span>
                Банановое пюре
              </li>
            </ul>
            <ul class="modal__ingr-list modal__ingr-list--2nd">
              <li>
                <span class="icon icon--kiwi"></span>
                Сок из киви
              </li>
              <li>
                <span class="icon icon--pinapple"></span>
                Ананасовый сок
              </li>
              <li>
                <span class="icon icon--orange"></span>
                Мякость апельсина
              </li>
              <li>
                <span class="icon icon--water"></span>
                Артезианская вода
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalFooter />

    </modal>

    <modal name="product-3" :scrollable="true" height="auto">
      <div class="modal__close img-w" @click="$modal.hide('product-3')">
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.5748 22.0092L22.1767 26.4073L39.7693 44L22.1767 61.5927L26.5748 65.9908L44.1675 48.3982L61.7602 65.9908L66.1583 61.5927L48.5657 44L66.1583 26.4073L61.7602 22.0092L44.1675 39.6018L26.5748 22.0092Z" fill="white"/>
        </svg>
      </div>

      <div class="modal__df">
        <div class="modal__img img-w">
          <img src="@/assets/img/main/3.png" alt="">
        </div>
        <div class="modal__column">
          <h3 class="modal__title">
            {{ 'Pulpy Гуава-маракуйя с кусочками кокосового желе' | typograf }}
          </h3>
          <div class="modal__volume">
            <span class="modal__part-title">Объем: </span> 0,45л
          </div>
          <p class="modal__descr">
            {{ 'Встряхни Pulpy Гуава-Маракуйя и открой для себя необычное сочетание тропических фруктов и кусочков кокосового желе' | typograf }}
          </p>
          <div class="modal__ingr">
            <span class="modal__part-title">Ингредиенты*</span>
          </div>
          <div class="modal__content-columns">
            <ul class="modal__ingr-list">
              <li>
                <span class="icon icon--orange"></span>
                Апельсиновый сок
              </li>
              <li>
                <span class="icon icon--apple"></span>
                Яблочное пюре
              </li>
              <li>
                <span class="icon icon--guava"></span>
                Пюре из гуавы
              </li>
            </ul>
            <ul class="modal__ingr-list modal__ingr-list--2nd">
              <li>
                <span class="icon icon--kuja"></span>
                Сок из маракуйи
              </li>
              <li>
                <span class="icon icon--coconut"></span>
                Кусочки кокосового желе
              </li>
              <li>
                <span class="icon icon--water"></span>
                Артезианская вода
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalFooter />

    </modal>

    <modal name="product-4" :scrollable="true" height="auto">
      <div class="modal__close img-w" @click="$modal.hide('product-4')">
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.5748 22.0092L22.1767 26.4073L39.7693 44L22.1767 61.5927L26.5748 65.9908L44.1675 48.3982L61.7602 65.9908L66.1583 61.5927L48.5657 44L66.1583 26.4073L61.7602 22.0092L44.1675 39.6018L26.5748 22.0092Z" fill="white"/>
        </svg>
      </div>

      <div class="modal__df">
        <div class="modal__img img-w">
          <img src="@/assets/img/main/4.png" alt="">
        </div>
        <div class="modal__column">
          <h3 class="modal__title">
            {{ 'Pulpy ананас-манго с кусочками ананаса' | typograf }}
          </h3>
          <div class="modal__volume">
            <span class="modal__part-title">Объем: </span> 0,45л
          </div>
          <p class="modal__descr">
            {{ 'Встряхни Pulpy Ананас-Манго и открой для себя удивительное сочетание натуральных кусочков ананаса, сока фруктов и артезианской воды ' | typograf }}
          </p>
          <div class="modal__ingr">
            <span class="modal__part-title">Ингредиенты*</span>
          </div>
          <div class="modal__content-columns">
            <ul class="modal__ingr-list">
              <li>
                <span class="icon icon--pinapple"></span>
                Ананасовый сок
              </li>
              <li>
                <span class="icon icon--mango"></span>
                Пюре из манго
              </li>
            </ul>
            <ul class="modal__ingr-list modal__ingr-list--2nd">
              <li>
                <span class="icon icon--pinapple"></span>
                Кусочки ананаса
              </li>
              <li>
                <span class="icon icon--water"></span>
                Артезианская вода
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalFooter />

    </modal>

    <modal name="product-5" :scrollable="true" height="auto">
      <div class="modal__close img-w" @click="$modal.hide('product-5')">
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.5748 22.0092L22.1767 26.4073L39.7693 44L22.1767 61.5927L26.5748 65.9908L44.1675 48.3982L61.7602 65.9908L66.1583 61.5927L48.5657 44L66.1583 26.4073L61.7602 22.0092L44.1675 39.6018L26.5748 22.0092Z" fill="white"/>
        </svg>
      </div>

      <div class="modal__df">
        <div class="modal__img img-w">
          <img src="@/assets/img/main/5.png" alt="">
        </div>
        <div class="modal__column">
          <h3 class="modal__title">
            {{ 'Pulpy Клубника-арбуз с кусочками алоэ' | typograf }}
          </h3>
          <div class="modal__volume">
            <span class="modal__part-title">Объем: </span> 0,45л
          </div>
          <p class="modal__descr">
            {{ 'Встряхни Pulpy Клубника-Арбуз и открой для себя необыкновенное сочетание сочных фруктов, артезианской воды и натуральных кусочков алоэ' | typograf }}
          </p>
          <div class="modal__ingr">
            <span class="modal__part-title">Ингредиенты*</span>
          </div>
          <div class="modal__content-columns">
            <ul class="modal__ingr-list">
              <li>
                <span class="icon icon--apple"></span>
                Яблочный сок
              </li>
              <li>
                <span class="icon icon--watermelon"></span>
                Арбузный сок
              </li>
              <li>
                <span class="icon icon--strawberry"></span>
                Клубничный сок
              </li>
            </ul>
            <ul class="modal__ingr-list modal__ingr-list--2nd">
              <li>
                <span class="icon icon--aloe"></span>
                Кусочки алоэ
              </li>
              <li>
                <span class="icon icon--water"></span>
                Артезианская вода
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalFooter />

    </modal>

    <modal name="product-6" :scrollable="true" height="auto">
      <div class="modal__close img-w" @click="$modal.hide('product-6')">
        <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.5748 22.0092L22.1767 26.4073L39.7693 44L22.1767 61.5927L26.5748 65.9908L44.1675 48.3982L61.7602 65.9908L66.1583 61.5927L48.5657 44L66.1583 26.4073L61.7602 22.0092L44.1675 39.6018L26.5748 22.0092Z" fill="white"/>
        </svg>
      </div>

      <div class="modal__df">
        <div class="modal__img img-w">
          <img src="@/assets/img/main/6.png" alt="">
        </div>
        <div class="modal__column">
          <h3 class="modal__title">
            Pulpy австралия <br> с кусочками алоэ
          </h3>
          <div class="modal__volume">
            <span class="modal__part-title">Объем: </span> 0,45л
          </div>
          <p class="modal__descr">
            {{ 'Открой вкус Австралии!' | typograf }} <br>
            {{ 'Встречай новый освежающий Pulpy с необычным сочетанием сочного киви, кусочков алоэ и ароматом экзотической гуанабаны' | typograf }}
          </p>
          <div class="modal__ingr">
            <span class="modal__part-title">Ингредиенты*</span>
          </div>
          <div class="modal__content-columns">
            <ul class="modal__ingr-list">
              <li>
                <span class="icon icon--apple"></span>
                Яблочный сок
              </li>
              <li>
                <span class="icon icon--grape"></span>
                Виноградный сок
              </li>
              <li>
                <span class="icon icon--kiwi"></span>
                Сок киви
              </li>
            </ul>
            <ul class="modal__ingr-list modal__ingr-list--2nd">
              <li>
                <span class="icon icon--aloe"></span>
                Кусочки алоэ
              </li>
              <li>
                <span class="icon icon--water"></span>
                Артезианская вода
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ModalFooter />

    </modal>-->
  </section>
</template>

<script>
import ModalFooter from '../ModalFooter/ModalFooter';
export default {
  name: 'ProductsMainline',
  components: { ModalFooter },
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    products() {
      return this.$store.getters.products;
    },
    list() {
      return this.$store.getters.products.attributes.product;
    },
    staticUrl() {
      return this.$store.getters.staticUrl;
    },
  },

  async mounted() {
    await this.$store.dispatch('getProducts');
    this.isReady = true;
  },
};
</script>

<style scoped lang="scss">
@import 'products';
@import 'anim';
</style>

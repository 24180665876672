<template>
  <div class="home">
    <KeyVisual />
    <ProductsBlock  />
    <ProductsMainline id="products" />
    <SeriesBlock />
    <VideoBlock v-if="0" id="series" />
  </div>
</template>

<script>
// @ is an alias to /src


import KeyVisual from "../components/KeyVisual/KeyVisual";
import ProductsBlock from "../components/ProductsBlock/ProductsBlock";
import ProductsMainline from "../components/ProductsMainline/ProductsMainline";
import VideoBlock from "../components/VideoBlock/VideoBlock";
import SeriesBlock from '../components/SeriesBlock/SeriesBlock.vue';

export default {
  name: 'HomeView',
  components: {
    VideoBlock,
    ProductsMainline,
    ProductsBlock,
    KeyVisual,
    SeriesBlock,
  },
  mounted() {
    setTimeout(() => {
      if (location.hash) {
        const target = location.hash;
        const hash = target.split('#')[1];
        const topPos = document.getElementById(hash).offsetTop;
        window.scrollTo({
          top: topPos,
          behavior: 'smooth'
        })
      }
    }, 500);
    
  }
}
</script>

<template>
  <div class="modal__footer">
    <div class="modal__links" v-if="links[id].auchan != ''">
      <h3 class="modal__part-title">Выберите интернет-магазин:</h3>
      <div class="modal__links-buttons">
        <div @click="showPopup(links[id].auchan)" class="modal__link-button img-w">
          <img src="@/assets/img/links/auchan.png" alt="" />
        </div>
        <div @click="showPopup(links[id].vprok)" class="modal__link-button img-w">
          <img src="@/assets/img/links/vprok.png" alt="" />
        </div>
        <div @click="showPopup(links[id].perekrestok)" class="modal__link-button img-w">
          <img src="@/assets/img/links/perekrestok.png" alt="" />
        </div>
      </div>
    </div>

    <!-- <div class="modal__starred-text">* Полный состав смотрите на этикетке продукции</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          auchan: '',
          vprok: '',
          perekrestok: '',
        },
        {
          auchan: 'https://www.auchan.ru/product/gm_sokosoderzhnappalpi_045/',
          vprok: 'https://www.vprok.ru/product/pulpy-dob-napit-sokos-apel-s-myak-det-pit-450ml--309645?searchPhrase=pulpy',
          perekrestok: 'https://www.perekrestok.ru/cat/425/p/napitok-sokosoderzasij-pulpy-apelsin-450ml-3418780',
        },
        {
          auchan: 'https://www.auchan.ru/product/napitok-sokosoderzhashchiy-dobryy-pulpy-tropicheskiy-s-myakotyu-450-ml/',
          vprok: 'https://www.vprok.ru/product/pulpy-dobr-nap-pulpy-trop-sokos-s-ap-myak0-45l--309671?searchPhrase=pulpy',
          perekrestok: 'https://www.perekrestok.ru/cat/425/p/napitok-sokosoderzasij-pulpy-tropiceskij-s-makotu-450ml-3444876',
        },
        {
          auchan: 'https://www.auchan.ru/product/napitok-pulpy-dobryy-s-marakuyey-guavoy-i-kokosom-450-ml/',
          vprok: 'https://www.vprok.ru/product/pulpy-dobr-nap-sokkus-kok-jele-0-45l--692321?searchPhrase=pulpy',
          perekrestok: 'https://www.perekrestok.ru/cat/425/p/napitok-sokosoderzasij-pulpy-marakua-guava-kokos-450ml-3947130',
        },
        {
          auchan: 'https://www.auchan.ru/product/napitok-pulpy-dobryy-ananas-mango-450-ml/',
          vprok: 'https://www.vprok.ru/product/pulpy-dobr-nap-palpi-vk-anman-0-45l--501169?searchPhrase=pulpy',
          perekrestok: 'https://www.perekrestok.ru/cat/425/p/napitok-sokosoderzasij-pulpy-ananas-mango-450ml-3671718',
        },
        {
          auchan: 'https://www.auchan.ru/product/napitok-pulpy-dobryy-s-klubnikoy-arbuzom-i-aloe-450-ml/',
          vprok: 'https://www.vprok.ru/product/pulpy-dobr-nap-sokkus-aloe-v-0-45l--692320?searchPhrase=pulpy',
          perekrestok: 'https://www.perekrestok.ru/cat/425/p/napitok-sokosoderzasij-pulpy-klubnika-arbuz-s-kusockami-aloe-450ml-3947129',
        },
        {
          auchan: '',
          vprok: '',
          perekrestok: '',
        },
      ],
    };
  },
  name: 'ModalFooter',

  props: {
    auchan: {
      default: 'https://www.auchan.ru/',
    },
    perekrestok: {
      default: 'https://www.perekrestok.ru/',
    },
    vprok: {
      default: 'https://www.vprok.ru/',
    },
    id: {
      default: 1,
    },
  },
  methods: {
    showPopup(url) {
      const data = {
        val: true,
        url: url,
      };
      this.$store.commit('CHANGE_POPUP_VISIBILITY', data);
    },
  },
};
</script>

<style scoped lang="scss"></style>

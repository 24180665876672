<template>
  <section class="prod" v-if="isReady">
    <div class="prod__block">
      <div class="prod__df">
        <div class="prod__column">
          <h2>{{ novelties.attributes.screen1title }}</h2>
          <p>{{ novelties.attributes.screen1description | typograf }}</p>
        </div>

        <div class="prod__img prod__img--1 img-w">
          <img src="@/assets/img/prod1.png" alt="" />
          <iframe src="https://pulpy.ru/landing-animation/?output=embed" frameborder="0"> </iframe>
        </div>
      </div>
    </div>

    <div class="prod__block prod__block--2" id="new">
      <div class="prod__line img-w">
        <img src="@/assets/img/line.png" alt="" loading="lazy" />
      </div>

      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="0"
        :loop="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }"
        navigation
      >
        <template #wrapper-start>
          <swiper-slide>
            <a class="prod__df prod__df--2 prod--first" href="https://www.superjob.ru/pulpy" target="_blank">
              <picture class="product-picture">
                <source media="(min-width: 1440px)" srcset="@/assets/img/banner/1900x840.webp" />
                <source media="(min-width: 601px)" srcset="@/assets/img/banner/1440x730.webp" />
                <source media="(min-width: 0px)" srcset="@/assets/img/banner/375x570.webp" />
                <img src="@/assets/img/banner/1900x840.webp" alt="" class="product-img" loading="lazy" />
              </picture>
            </a>
          </swiper-slide>
          <swiper-slide>
            <div class="prod__df prod__df--2">
              <div class="prod__img prod__img--2 img-w">
                <img src="@/assets/img/prod4.png" alt="" loading="lazy" />
              </div>
              <div class="prod__button-box mobile-visible">
                <button class="button button--white" @click="$modal.show('product-7')">О продукте</button>
              </div>
              <div class="prod__column prod__column--2">
                <div class="prod__newMark">НОВИНКА</div>
                <h2>{{ novelties.attributes.screen2title | typograf }}</h2>
                <p>{{ novelties.attributes.screen2description | typograf }}</p>
                <div class="prod__button-box mobile-hidden">
                  <button class="button button--white" @click="$modal.show(`product-${novelties.attributes.screen2buttonUrl}`)">
                    {{ novelties.attributes.screen2buttonText }}
                  </button>
                </div>
              </div>
            </div>
          </swiper-slide>
        </template>
      </swiper>

      <div class="prod__line prod__line--left img-w">
        <img src="@/assets/img/line.png" alt="" loading="lazy" />
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

export default {
  components: { Swiper, SwiperSlide },
  name: 'ProductsBlock',
  setup() {
    return { modules: [Autoplay, Navigation] };
  },
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    novelties() {
      return this.$store.getters.novelties;
    },
  },

  async mounted() {
    await this.$store.dispatch('getNovelties');
    this.isReady = true;
  },
};
</script>

<style scoped lang="scss">
@import 'products';

p {
  white-space: pre-wrap;
}
</style>

<template>
  <section class="video" v-if="isReady">
    <div class="video__like-circle-bg img-w">
      <img src="@/assets/img/like-circle-bg.png" alt="">
    </div>

    <div class="video__preview img-w">
      <img v-if="video.attributes.image.data"
           :src="$store.getters.staticUrl + video.attributes.image.data.attributes.url" alt="">
    </div>

    <div class="video__button-box mobile-visible">
      <button class="button">{{ video.attributes.buttonText }}</button>
    </div>

    <div class="video__column">
      <h2>{{ video.attributes.title }}</h2>
      <p>{{ video.attributes.description | typograf }}</p>
      <div class="video__button-box mobile-hidden">
        <button class="button">{{ video.attributes.buttonText }}</button>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "VideoBlock",
    data() {
      return {
        isReady: false,
      }
    },
    computed: {
      video() {
        return this.$store.getters.video
      }
    },

    async mounted() {
      await this.$store.dispatch('getVideo')
      this.isReady = true
    }
  }
</script>

<style scoped lang="scss">
  @import "video";

  p {
    white-space: pre-wrap;
  }
</style>

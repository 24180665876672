import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/filters';
import VueGtm from 'vue-gtm';

import VModal from 'vue-js-modal'
import VueScrollTo from 'vue-scrollto'

Vue.use(VueScrollTo)
Vue.use(VModal)
Vue.use(
  VueGtm,
    {
      id: 'GTM-WWC3WVM',
      enabled: true,
      debug: true,
      vueRouter: router
    }

)
import inViewportDirective from 'vue-in-viewport-directive'
Vue.directive('in-viewport', inViewportDirective)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

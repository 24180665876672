var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isReady)?_c('section',{staticClass:"prod"},[_c('div',{staticClass:"prod__block"},[_c('div',{staticClass:"prod__df"},[_c('div',{staticClass:"prod__column"},[_c('h2',[_vm._v(_vm._s(_vm.novelties.attributes.screen1title))]),_c('p',[_vm._v(_vm._s(_vm._f("typograf")(_vm.novelties.attributes.screen1description)))])]),_vm._m(0)])]),_c('div',{staticClass:"prod__block prod__block--2",attrs:{"id":"new"}},[_vm._m(1),_c('swiper',{attrs:{"modules":_vm.modules,"slides-per-view":1,"space-between":0,"loop":true,"autoplay":{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },"navigation":""},scopedSlots:_vm._u([{key:"wrapper-start",fn:function(){return [_c('swiper-slide',[_c('a',{staticClass:"prod__df prod__df--2 prod--first",attrs:{"href":"https://www.superjob.ru/pulpy","target":"_blank"}},[_c('picture',{staticClass:"product-picture"},[_c('source',{attrs:{"media":"(min-width: 1440px)","srcset":require("@/assets/img/banner/1900x840.webp")}}),_c('source',{attrs:{"media":"(min-width: 601px)","srcset":require("@/assets/img/banner/1440x730.webp")}}),_c('source',{attrs:{"media":"(min-width: 0px)","srcset":require("@/assets/img/banner/375x570.webp")}}),_c('img',{staticClass:"product-img",attrs:{"src":require("@/assets/img/banner/1900x840.webp"),"alt":"","loading":"lazy"}})])])]),_c('swiper-slide',[_c('div',{staticClass:"prod__df prod__df--2"},[_c('div',{staticClass:"prod__img prod__img--2 img-w"},[_c('img',{attrs:{"src":require("@/assets/img/prod4.png"),"alt":"","loading":"lazy"}})]),_c('div',{staticClass:"prod__button-box mobile-visible"},[_c('button',{staticClass:"button button--white",on:{"click":function($event){return _vm.$modal.show('product-7')}}},[_vm._v("О продукте")])]),_c('div',{staticClass:"prod__column prod__column--2"},[_c('div',{staticClass:"prod__newMark"},[_vm._v("НОВИНКА")]),_c('h2',[_vm._v(_vm._s(_vm._f("typograf")(_vm.novelties.attributes.screen2title)))]),_c('p',[_vm._v(_vm._s(_vm._f("typograf")(_vm.novelties.attributes.screen2description)))]),_c('div',{staticClass:"prod__button-box mobile-hidden"},[_c('button',{staticClass:"button button--white",on:{"click":function($event){return _vm.$modal.show(`product-${_vm.novelties.attributes.screen2buttonUrl}`)}}},[_vm._v(" "+_vm._s(_vm.novelties.attributes.screen2buttonText)+" ")])])])])])]},proxy:true}],null,false,3769879878)}),_vm._m(2)],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prod__img prod__img--1 img-w"},[_c('img',{attrs:{"src":require("@/assets/img/prod1.png"),"alt":""}}),_c('iframe',{attrs:{"src":"https://pulpy.ru/landing-animation/?output=embed","frameborder":"0"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prod__line img-w"},[_c('img',{attrs:{"src":require("@/assets/img/line.png"),"alt":"","loading":"lazy"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"prod__line prod__line--left img-w"},[_c('img',{attrs:{"src":require("@/assets/img/line.png"),"alt":"","loading":"lazy"}})])
}]

export { render, staticRenderFns }